<template>
  <div class="n-header">
    <!-- 上层 -->
    <div class="s">
      <div class="container">
        <!-- logo -->
       <div class="n-logo">
         <img src="/imgs/logo.png" alt="">
       </div>
        <!-- <h2>飞拓新创</h2> -->
      </div>
    </div>
    <!-- 下层 -->
    <div class="x">
      <div class="container">
        <!-- 导航 -->
        <a href="/">网站首页</a>
        <a href="/#/ac1">行业资讯</a>
        <!-- <a href="/#/xy">用户协议</a> -->
        <!-- <a href="/#/ac4">服务项目</a> -->
        <!-- <a href="/#/ac4">服务项目2</a> -->
        <!-- <a href="/#/ac3">用户权益保障措施</a> -->
        <a class="n-login" @click="goLogin()" v-show="this.LoggedShow">
          登录
        </a>

        <!-- 注册 -->
        <a class="n-login" @click="goLogin()" v-show="this.LoggedShow">
          立即注册
        </a>
        <!-- 用户名 -->
        <a
          class="n-info"
          v-show="!this.LoggedShow"
          v-text="this.nv_user.Username"
          ><i class="iconfont icon-denglu"></i
        ></a>
        <!-- 退出登录 -->
        <a class="n-info" @click="tolg()" v-show="!this.LoggedShow">
          <i class="iconfont icon-tuichudenglu"></i>
          退出登录
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 按钮显示/隐藏
      LoggedShow: true,
      // 获取到 user 用户信息
      nv_user: JSON.parse(window.localStorage.getItem("user")),
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.logged();
  },
  methods: {
    // 登录时隐藏逻辑
    logged() {
      if (this.state == "0") {
        this.LoggedShow = false;
      }
    },

    // 登录跳转
    goLogin() {
      this.$router.push("/login");
    },
    // 注册跳转
    goRegister() {
      this.$router.push("/register");
    },
    // 退出登录
    tolg() {
      window.localStorage.setItem("state", "10");
      this.LoggedShow = true;
      location.reload();
      // this.$forceUpdate()
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.n-header {
  width: 100%;
  // height: 180px;
  background: #fff;
  position: relative;
  border-bottom: 1px solid $colorZ;
  // 上层
  .s {
    width: 100%;
    height: 80px;
    margin-bottom: 15px;
    .container {
      height: 100%;
      // background-color: pink;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      // logo
      .n-logo {
        width: 190px;
        // height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      h2 {
        font-size: 21px;
        color: #333;
        font-weight: 400;
        margin-left: 30px;
      }
    }
  }
  // 下层
  .x {
    width: 100%;
    height: 60px;
    background: $colorZ;

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        // width: ;
        font-size: 14px;
        color: #fff;
        height: 60px;
        line-height: 60px;
        text-align: center;
        display: inline-block;
        // background-color: #c60023;
        flex: 1;
        margin: 0 15px;
        cursor: pointer;
        transition: all .2s;
        &:hover {
          background-color: #fafafa;
          color: $colorZ;
        }
      }
    }
  }
}
</style>