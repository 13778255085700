<template>
  <div class="a2">
    <!-- banner区块 -->
    <div class="banner">
      <img src="/imgs/b3.jpg" alt="" />
    </div>

    <div class="container">
      <!-- 客户案例 -->
      <!-- 标题 -->
      <div class="y-title">
        <h2>客戶案例</h2>
        <div class="t-info">
          <div class="blink"></div>
          <span>Customer case</span>
          <div class="blink"></div>
        </div>
      </div>

      <!-- 案例容器 -->
      <div class="s2-list">
        <div class="s2-item" v-for="(item, index) in this.al" :key="index">
          <img @click="goDetails(item.id)" :src="item.imgs" alt="" />
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      al: [],
    };
  },
  mounted() {
    this.init2();
    document.title = "淘禮網有限公司";
  },
  methods: {
    init2() {
      this.axios.get("/mock/al.json").then((res) => {
        const data = res.data;
        console.log(data);
        this.al = data;
      });
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 收费逻辑
    gopay() {
      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
      }
      // 未登录
      if (this.state !== 0) {
        alert("请登录付费3元后可浏览！！！~");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound3/:" + id,
        query: { type: id },
      });
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.a2 {
  width: 100%;
  position: relative;
  // banner
  .banner {
    width: 100%;
    height: 400px;
    // background-color: pink;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .container {
    // 标题
    .y-title {
      width: 100%;
      text-align: center;
      margin: 30px auto;
      margin-top: 60px;
      h2 {
        font-size: 24px;
        font-weight: 400px;
      }
      .t-info {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-size: 16px;
          color: #888;
          margin: 0 10px;
        }
        .blink {
          width: 60px;
          height: 2px;
          background-color: $colorZ;
        }
      }
    }
    // 案例容器
    .s2-list {
      width: 100%;
      height: 500px;
      //   background-color: pink;
      margin: 30px auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .s2-item {
        img {
          width: 300px;
          height: 180px;
          // background-color: #c60023;
          margin: 0 20px;
        }
        p {
          font-size: 16px;
          text-align: center;
          font-weight: bold;
          margin-top: 5px;
        }
      }
    }
  }
}
</style>