<template>
  <div>
    <!-- 导航栏 -->
    <!-- <nav-header :href="this.href"></nav-header> -->

    <div class="container">
      <!-- 纠纷处理 -->
      <div class="ag-con">
        <h3>用户服务协议</h3>
        <div class="article-content">
          欢迎使用思亿文化！本协议系思亿文化与您之间就思亿文化服务等相关事宜所订立的协议，本协议具有合同效力。<br />
          您点击同意本协议的，即视为您确认自己具有享受思亿文化服务、下单购物等相应的权利能力和行为能力，能够独立承担法律责任。您应当在使用思亿文化服务之前审慎阅读、充分理解本协议之各条款内容，对于协议中以加粗字体显示的内容，您应重点阅读，特别是免除或者限制责任的条款，以及开通或使用某项服务的单独协议。<br />
          <strong>您理解并同意，只要您使用</strong><strong>思亿文化</strong
          ><strong>服务，则本协议即对您产生约束</strong
          >，届时您不应以未阅读本协议的内容等理由，主张本协议无效，或要求撤销本协议。
          您承诺接受并遵守本协议的约定。如果您不同意本协议的约定，您应立即停止注册程序或停止使用思亿文化服务。<br />
          一. 定义<br />
          1.1【本协议】系您与思亿文化之间关于使用思亿文化相关服务所订立的协议。<br />
          1.2【思亿文化思亿文化】系北京思亿文化网络科技有限公司旗下相关平台的简称<br />
          1.3【用户】：系思亿文化相关服务的使用人，在本协议中简称为“您”。<br />
          1.4【服务提供方】：系指向您提供阅读等服务的北京思亿文化网络科技有限公司。<br />
          1.5【服务】指您访问思亿文化平台时捷向您提供的产品和服务。本协议项下的服务内容包括但不限于用户在线免费阅读、用户注册免费阅读、用户注册付费阅读、电商服务、思亿文化音乐联运等。随着业务的发展，思亿文化所提供的服务会随之增多，本协议未说明到的服务，以思亿文化平台的服务内容为准。<br />
          二. 承诺与保证<br />
          （一）承诺条款<br />
          2.1您理解并同意：<br />
          （1）思亿文化有权对您违反本协议项下的承诺时，适用平台规则予以处理或终止向您提供服务，且无需征得您的同意或提前通知您。<br />
          （2）
          对于您在思亿文化平台上发布的涉嫌违法或违反本协议的信息，思亿文化有权不通知您即予以删除，并按照法律法规及本协议的规定进行处罚。<br />
          （3）对于您就思亿文化服务实施的行为，包括您未在思亿文化平台上实施但已经对思亿文化平台及其用户产生影响的行为，思亿文化有权根据您行为的性质及是否构成对本协议的违反，据此作出相应处罚。您应自行保存与您行为有关的全部证据，并应就无法提供充要证据而承担可能的不利后果。<br />
          （4）对于您涉嫌违反承诺的行为对任意第三方造成损害的，您应当以自己的名义独立承担所有的法律责任，并应确保思亿文化免于因此产生损失或增加费用。<br />
          （5）如您涉嫌违反有关法律或者本协议之规定，使思亿文化遭受任何损失，或受到任何第三方的索赔，或受到任何行政管理部门的处罚，您应当赔偿思亿文化因此造成的损失，支付因此产生的费用，包括律师费用、公证费等相关的全部费用。<br />
          （二）特别授权<br />
          2.2您完全理解并不可撤销地授予思亿文化下列权利：<br />
          （1）一旦您向思亿文化作出任何形式的承诺，且相关公司已确认您违反了该承诺，则思亿文化有权立即按承诺或协议约定的方式对您的账户采取限制措施，包括中止或终止向您提供服务。您理解并同意，思亿文化无需就相关公司已确认的信息与您核实或另行征得您的同意，且思亿文化无需就限制措施或公示行为向您承担任何的责任。<br />
          （2）&nbsp;<strong>对于您提供的资料及数据信息、发布的内容，您授予</strong
          ><strong>思亿文化</strong
          ><strong
            >独家的、全球通用的、永久的、免费的许可使用权利，并有权在多个层面对该权利进行再授权。</strong
          >此外，思亿文化有权全部或部分地使用、复制、修订、改写、发布、翻译、分发、执行和展示您的全部资料数据（包括但不限于注册资料、交易行为数据及全部展示于思亿文化平台的各类信息）或制作其派生作品，并以已知或日后开发的媒体、技术等任何形式，将上述信息纳入其它作品内。<br />
          三. 服务条款的确认及接受<br />
          3.1&nbsp;<strong>思亿文化</strong><strong>各项服务的所有权和运作权归</strong
          ><strong>思亿文化</strong
          ><strong>所有。</strong
          >您确认：本协议条款是处理双方权利义务的依据之一，始终有效，法律另有强制性规定或双方另有约定的，依其规定或约定。<br />
          3.2本协议内容包括本协议正文及所有思亿文化已经发布的或将来可能发布的各项规则。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。除另行明确声明外，任何思亿文化提供的服务均受本协议约束。<br />
          3.3思亿文化有权根据互联网的发展和中华人民共和国有关法律、法规的变化，不时地完善服务质量并依此修改本服务协议条款。您的权利以及义务的表述，均以最新的服务条款为准，服务条款一旦发生变动，将以网站公示的方式进行公告，不单独通知您。变更后的协议和规则一经公布，立即自动生效。<strong
            >您知悉并确认，如您不同意更新后的内容，应立即停止使用</strong
          ><strong>思亿文化</strong
          ><strong>相关服务；如您继续使用，即视为知悉变动内容并同意接受。</strong><br />
          3.4您在使用思亿文化某一特定服务时，该服务可能另有单独的协议或规则（以下统称为“单独协议”），您在使用该项服务前请阅读并同意相关的单独协议；您使用前述特定服务，即视为您已阅读并同意接受相关单独协议。<br />
          四. 服务内容<br />
          4.1 在线免费听歌。<br />
          在未注册并登录思亿文化平台的情况下，您有权在线使用思亿文化提供的部分服务，您有权查找、浏览思亿文化提供的部分思亿文化作品，有权浏览思亿文化V社区及思亿文化商城的部分内容。<br />
          4.2 注册免费听歌。<br />
          您有权在注册并登录后，使用思亿文化提供的如下服务（思亿文化有权变更和修改服务内容）：
          您有权在注册并登陆后，查找、浏览思亿文化作品，在线听歌。<br />
          您有权在注册并登陆后，在相应的思亿文化作品讨论区或思亿文化V社区发表、回复言论；
          您有权在注册并登陆后，参与思亿文化相关思亿文化作品的线上或线下活动，并获得活动给予的奖励等。<br />
          4.3 注册付费下载<br />
          4.3.1思亿文化部分服务是以付费方式提供的，如您使用本服务，请遵守相关的协议。<br />
          4.3.2在思亿文化平台中，您可以通过一定支付手段用于您账号的充值，支付手段包括但不限于微信、支付宝、QQ钱包等。思亿文化有权修改、变更支付手段。<br />
          4.3.3注册付费下载服务的支付工具为乐币。乐币为思亿文化平台专有的具有用于支付付费阅读服务费用、购买会员或打赏作者等功能的虚拟货币。乐币可直接充值获取，也可以通过参与活动或充值赠送获得。充值获取的乐币获得后无有效期限制；赠送获得的乐币，购买付费作品时会优先抵扣，有一定的有效期，过期会自动清空。思亿文化有权变更、修改支付工具及其主要功能和用途。<br />
          4.3.4您理解并同意，乐币仅限于思亿文化平台使用，思亿文化不支持任何形式的用户间交易乐币的行为，不保护用户间交易乐币的任何交易结果，用户间交易乐币的行为构成对本协议的违反，思亿文化平台有权在不通知的情况下，采取相应措施，确保平台不出现用户间交易乐币的行为。<br />
          4.3.5根据实际需要，思亿文化可能对付费下载服务的收费标准、方式进行修改和变更，有可能将付费作品转成免费，也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，思亿文化将在相应服务页面进行通知或公告，而不会对已付费的用户做出补偿。如果您不同意上述修改、变更的付费内容，应停止使用该服务。<br />
          4.5音乐联运服务<br />
          您有权获得思亿文化独家授权（即“独代”）或者与其他平台联合运行提供的相关音乐服务。如您选择获得此项服务，应遵守相关的音乐服务协议<br />
          4.5.1音乐产品和服务的具体内容由思亿文化根据实际情况提供。思亿文化保留随时变更、中断或终止部分或全部产品和服务的权利。<br />
          4.5.2除非获得思亿文化书面许可，否则，您不得利用思亿文化的任何产品和服务及其任何内容牟取商业利益，包括但不限于充当音乐道具交易中介收取中介费，以营利为目的销售音乐道具等。<br />
          4.5.3您可能在使用思亿文化相关服务的过程中链接到第三方的站点。第三方的站点不由思亿文化控制，并且思亿文化也不对任何第三方站点的内容、包含的任何链接以及站点的任何更新和变更负责。您需要检查并遵守该第三方站点的相关规定。<br />
          4.6广告发布<br />
          4.6.1您同意思亿文化可以在提供服务的过程中自行或由第三方广告商向您发送广告、推广或宣传信息（包括商业与非商业信息），其方式和范围可不经通知您而变更。思亿文化可能使用您的信息，向您提供与您更加相关的广告。<br />
          4.6.2思亿文化可能使用您的信息，通过服务、电子邮件或其他方式向您发送营销信息，提供或推广思亿文化及（或）第三方的如下商品和服务：<br />
          （1)商品和服务，包括但不限于：即时通讯服务、网上媒体服务、互动娱乐服务、社交网络服务、付款服务、互联网搜索服务、位置和地图服务、应用软件和服务、数据管理软件和服务、网上广告服务、互联网金融及其他社交媒体、娱乐、电子商务、资讯及通讯软件和服务（“互联网服务”）；<br />
          （2）第三方商品和服务，包括但不限于：互联网服务、食物和餐饮、体育、音乐、电影、电视、现场表演和其他艺术和娱乐、书册、杂志和其他刊物、服装和配饰、珠宝、化妆品、个人健康和卫生、电子、收藏品、家用器皿、电器、家居装饰和摆设、宠物、汽车、酒店、交通和旅游、银行、保险及金融服务、会员积分和奖励计划，以及思亿文化认为可能与您相关的其他商品和服务。<br />
          如您不希望思亿文化将您的个人信息用作前述广告用途，您可以通过思亿文化在广告中提供的相关指示、或在特定服务中提供的选择机制，要求停止为上述用途使用您的个人信息。<br />
          4.6.3思亿文化依照法律的规定对第三方广告商履行相关义务，您应当自行判断广告信息的真实性并为自己的判断行为负责，除法律法规明确规定外，您因广告信息进行的交易或前述第三方广告商提供的内容而遭受的损失或损害，思亿文化不承担责任。<br />
          4.7社区服务<br />
          4.7.1思亿文化社区服务的具体内容由思亿文化根据实际情况提供，包括但不限于授权您通过其帐号发布文字、图片、音频、视频、链接等，思亿文化社区有权对其提供的服务或产品形态进行升级或其他调整。<br />
          4.7.2您应对使用思亿文化社区服务发布的信息内容的真实性、合法性负责。您在发布信息内容时，如需获得该信息内容权利人的事先授权或批准的，应事先获得该等授权或批准；一旦您发布某项信息内容，将被视为您已事先获得该等授权或批准。因您发布的信息内容违反相关法律法规规定或侵犯第三方合法权利的，因此造成的纠纷由您自行解决，给思亿文化造成损失的，您应该负责赔偿。<br />
          4.7.3<strong>您有权使用</strong><strong>思亿文化</strong
          ><strong>提供的社区服务，您使用</strong><strong>思亿文化</strong
          ><strong>社区服务应遵守《</strong><strong>思亿文化</strong
          ><strong>社区内容管理规则》以及</strong><strong>思亿文化</strong
          ><strong>社区后续可能发布的其他规则。</strong
          >&nbsp;如您发布的信息内容被思亿文化认为违反法律法规规定、侵犯第三方合法权利、违反思亿文化发布的上述规则的，思亿文化有权采取包括但不限于以下一项或多项措施以减轻您的行为带来的影响：<br />
          （1）更改、删除或屏蔽相关信息内容；<br />
          （2）警告违规帐号、帐号禁言；<br />
          （3）冻结您账户资金，用于弥补您给思亿文化、第三方造成的损失；<br />
          （4）变更、限制或禁止违规帐号部分或全部功能；<br />
          （5）暂停、限制或终止您使用服务的权利、注销您的帐号等；<br />
          （6）向有关监管部门或国家机关报告；<br />
          （7）其他思亿文化认为合理的措施。<br />
          4.7.4为维护思亿文化社区的稳定运营，确保用户体验质量，未经思亿文化事先书面许可，任何人不得擅自在思亿文化社区上实施自动化行为或发布垃圾信息。<br />
          （1）自动化行为，是指以您自行或委托他人采用自动化手段或明显异于常人的、远高于正常用户频率地发布、评论、私信或作出关注、评论、抓取数据等的行为。<br />
          （2）垃圾信息，是指未经思亿文化同意，擅自使用帐号（包括通过作弊手段批量注册的帐号、普通帐号等）在主贴、评论、账号信息、话题专栏中发布的营销信息、无意义信息或卖粉信息。<br />
          （3）您知悉并同意，思亿文化有权根据技术规则通过检测验证等方式判断您帐号行为是否构成自动化行为、您帐号所发布的信息是否为垃圾信息，并采取相关措施予以处理。所有处理措施所依据的数据及信息（包括但不限于帐号操作记录、内容等）均以思亿文化社区后台记录为准。<br />
          4.7.5您知悉、理解并同意授权思亿文化可在全球范围内、完全免费、可转授权地使用您通过思亿文化社区发布的内容，前述内容包括但不限于文字、图片、视频等。具体来说，可能会包括：<br />
          （1）将前述内容通过自身或其他第三方技术、网络等在思亿文化选择的网络平台、应用程序或产品中，以有线或无线网，通过免费或收费的方式在不同终端（包括但不限于电脑、手机、互联网电视、机顶盒及其他上网设备等）以不同形式（包括但不限于点播、直播、下载等）进行网络传播或电信增值服务等；<br />
          （2）将前述内容复制、翻译、编入思亿文化当前已知或以后开发的作品、媒体或技术中，用于相关用途开发或推广宣传等；<br />
          （3）将前述内容授权给电台、电视台、网络媒体、运营商平台等与思亿文化有合作的媒体或运营商播放、传播，用于相关推广宣传等；<br />
          （4）其他思亿文化出于善意或另行取得您授权的使用行为。<br />
          4.7.6<strong>您在使用</strong><strong>思亿文化</strong
          ><strong
            >社区服务的过程中应遵守国家法律法规及政策规定，不得侵犯第三方合法权利，因您使用服务而产生的行为后果由您自行承担。</strong
          ><br />
          4.7.7通过思亿文化社区服务发布的任何信息，及通过服务传递的任何观点不代表思亿文化之立场，思亿文化亦不对其完整性、真实性、准确性或可靠性负责。您对于可能会接触到的非法的、非道德的、错误的或存在其他失宜之处的信息，及被错误归类或是带有欺骗性的发布内容，应自行做出判断。<br />
          4.7.8如果您发现思亿文化社区上存在侵犯您合法权益的内容，您可通过联系思亿文化社区管理员或发送邮件至245778363@qq.com邮箱向思亿文化进行投诉。为了保证您的问题能够得到及时有效地处理，请务必提交真实有效、完整清晰的材料，否则投诉将无法受理。您需要向思亿文化提供的材料包括：<br />
          （1）权利人的姓名（名称）、联系方式、地址及身份证明；<br />
          （2）权利人对涉嫌侵权内容拥有商标权、著作权或其他依法可以行使权利的权属证明；<br />
          （3）侵权内容的名称和网络地址。<br />
          <strong>五. 注册用户帐号及密码</strong><br />
          5.1符合下列条件之一的个人、组织，才能申请成为思亿文化用户、使用本协议项下的服务：<br />
          （1）年满十八周岁，并具有民事权利能力和民事行为能力的自然人；<br />
          （2）未满十八周岁，但监护人（包括但不限于父母）予以书面同意的自然人；<br />
          （3）根据中国法律、法规成立并合法存在的公司等企业法人、事业单位、社团组织和其他组织。无民事行为能力人、限制民事行为能力人以及无经营或特定经营资格的组织不当注册为用户的，其与思亿文化之间的协议自始无效，思亿文化一经发现，有权立即注销（永久冻结）该用户。<br />
          若您不具备前述主体资格，则您及您的监护人应承担因此而造成的一切后果，且思亿文化有权注销您的账户，并向您及您的监护人索偿。<br />
          5.2您注册成功后，思亿文化将给予每个注册用户一个帐号及相应的密码，该帐号和密码由您负责保管。<br />
          5.3您注册成为思亿文化用户的，应当使用真实的身份信息及个人资料，不得以虚假或冒用的居民身份信息、企业信息进行注册；不得冒用关联机构或社会名人注册用户名。您设置的用户名应当合法合规，不得侵犯或涉嫌侵犯他人任何合法权益。<br />
          5.4<strong>您应对您的账户和密码的安全，以及对通过您的账户和密码实施的行为负责。</strong>思亿文化特别提醒用户妥善审慎保管您的账号及密码，每次使用完毕后，应当安全退出。如果发现任何人不当使用您的账户或有任何其他可能危及您的账户安全的情形时，您应当立即以有效方式通知思亿文化，要求思亿文化暂停相关服务。您理解思亿文化对您的请求采取行动需要合理时间，思亿文化对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。因黑客行为或注册用户保管疏忽致使帐号被他人非法使用、失窃或造成其他利益损失，思亿文化不承担任何责任。<br />
          5.5除非有法律规定或司法裁定，且征得思亿文化的书面同意，否则，账户和密码不得以任何方式转让、赠与或继承（与账户相关的财产权益依照法律法规处理的除外）。<br />
          5.6如您在申请开通思亿文化服务后在任何连续90日内未实际使用的，则思亿文化有权在法律法规允许的范围内选择采取以下任何一种方式或多种方式进行处理：<br />
          （1）回收您的用户名；<br />
          （2）回收您的用户账号；<br />
          （3）停止为您提供服务。<br />
          <strong>六. 注册信息和隐私保护</strong><br />
          6.1思亿文化谨守保护您个人信息及隐私的基本准则。思亿文化对相关信息采取专业加密存储与传输方式，利用合理措施保障您个人信息的安全。<br />
          6.2思亿文化帐号（即思亿文化用户ID）的所有权归思亿文化所有，您完成注册申请手续后，获得思亿文化帐号的使用权。<br />
          6.3您知悉并同意，为方便您使用本软件相关服务，本软件将存储您在使用时的必要信息。您应提供及时、详尽及准确的个人资料，并不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将作为注册资料。如果因您的注册信息不真实而引起的问题及其产生的后果，思亿文化不承担任何责任。<br />
          6.4思亿文化不会向任何第三方披露或提供单个用户的注册资料，除非：<br />
          （1）事先获得您的明确授权；<br />
          （2）只有透露您的个人资料，才能提供您所要求的产品和服务；<br />
          （3）根据有关的法律法规要求；<br />
          （4）按照相关政府主管部门的要求；<br />
          （5）为维护思亿文化的合法权益。<br />
          6.5在您注册思亿文化帐户，使用思亿文化相关服务，或访问思亿文化平台时，思亿文化会收集您的个人身份识别资料，并会将这些资料用于改进为您提供的服务及平台内容。<br />
          6.6思亿文化有权根据实际情况，在法律法规规定范围内自行决定单个用户在本软件及服务中数据的最长储存期限以及用户日志的储存期限，并在服务器上为其分配数据最大存储空间等。<br />
          <strong>七. 服务内容及修改、中断、终止</strong><br />
          7.1关于软件的获取与更新：<br />
          (1)您可以直接从合法网站上获取本软件，也可以从得到思亿文化授权的第三方获取，如果您从未经思亿文化授权的第三方获取本软件或与本软件名称相同或相似的安装程序，思亿文化无法保证该软件能够正常使用，对因此给您造成的损失不负任何责任；<br />
          (2)为了改善用户体验、完善服务内容，思亿文化将不断努力开发新的服务，并为您不时提供软件更新，本软件新版本发布后，旧版本的软件可能无法使用，思亿文化不保证旧版本软件继续可用及相应的客户服务，请您随时核对并下载最新版本。<br />
          7.2思亿文化服务的具体内容由平台根据实际情况提供。<br />
          7.3与思亿文化提供相关服务有关的设备（如个人电脑、手机、调制解调器及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费）均由您自行负担。<br />
          7.4鉴于网络服务的特殊性，您同意思亿文化有权不经事先通知，随时变更、中断或终止部分或全部的网络服务（包括收费网络服务）。思亿文化不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。<br />
          7.5思亿文化需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，如因此类情况而造成网络服务（包括收费网络服务）在合理时间内的中断，思亿文化无需为此承担任何责任。思亿文化保留不经事先通知为维修保养、升级或其它目的暂停全部或部分的网络服务的权利。<br />
          7.6思亿文化有权于任何时间暂时或永久修改或终止本服务（或其任何部分），而无论其通知与否，思亿文化对用户和任何第三人均无需承担任何责任。<br />
          7.7您同意思亿文化基于其自身考虑，因任何理由，包括但不限于长时间未使用，或思亿文化认为您已经违反本协议的文字及精神，终止您的密码、帐号或相关服务之使用（或服务之任何部分），并将您在本服务内任何内容加以移除或删除。您同意依本协议任何规定，无需就相关事项进行事先通知即可中断或终止，您理解并同意，思亿文化可立即关闭或删除您的帐号及您帐号中所有相关信息及文件，或禁止继续使用前述文件或本服务。此外，您同意若本服务之使用被中断或终止或您的帐号及相关信息和文件被关闭或删除，思亿文化对您或任何第三人均不承担任何责任。<br />
          <strong>八. 用户权责</strong><br />
          8.1您承诺其拥有与本协议5.1所列条件相符的适格的能力。<br />
          8.2您有权按照思亿文化规定的程序和要求使用思亿文化向注册用户提供的各项网络服务，如果注册用户对该服务有异议，可以与思亿文化联系以便得到及时解决。<br />
          8.3您在申请使用思亿文化服务时，必须向思亿文化提供准确的个人资料，如个人资料有任何变动，必须及时更新，如思亿文化认为有需要，可通过电子邮件等合理方式催告您及时更新真实的信息。<br />
          8.4用户在使用思亿文化服务时，必须遵守中华人民共和国相关法律法规的规定，不得利用本服务进行任何违法或不正当的活动，包括但不限于下列行为∶<br />
          8.4.1 制作、复制、发布、传播、储存或以其它方式传送含有下列内容之一的信息：<br />
          （1）反对宪法所确定的基本原则的；<br />
          （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br />
          （3）损害国家荣誉和利益的；<br />
          （4）煽动民族仇恨、民族歧视、破坏民族团结的；<br />
          （5）破坏国家宗教政策，宣扬邪教和封建迷信的；<br />
          （6）散布谣言，扰乱社会秩序，破坏社会稳定的；<br />
          （7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br />
          （8）侮辱或者诽谤他人，侵害他人合法权利的；<br />
          （9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；<br />
          （10）以非法民间组织名义活动的；<br />
          （11）含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；<br />
          （12）含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的。<br />
          8.4.2不得利用思亿文化服务从事以下活动：<br />
          （1）未经允许，进入计算机信息网络或者使用计算机信息网络资源；<br />
          （2）未经允许，对计算机信息网络功能进行删除、修改或者增加；<br />
          （3）未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加；<br />
          （4）故意制作、传播计算机病毒等破坏性程序；<br />
          （5）其他危害计算机信息网络安全的行为。<br />
          8.5您通过思亿文化相关的服务发送或传播的内容（包括但不限于网页、文字、图片、音频、视频、图表等）应有合法来源，相关内容为您所有或您已获得权利人的授权。您违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，您同意赔偿思亿文化，并应确保思亿文化免于因此产生损失或增加费用。<br />
          8.6
          如果思亿文化收到权利人或有关司法机关的通知，认定您发送或传播的内容侵犯相关权利的，您同意思亿文化有权视您的行为性质，采取包括但不限于删除用户发布信息内容、暂停使用许可、终止服务、限制使用、回收思亿文化帐号、追究法律责任等措施。对恶意注册思亿文化帐号或利用其进行违法活动，捣乱、骚扰、欺骗、其他用户以及其他违反本协议的行为，思亿文化有权回收其帐号。<br />
          如果您违反了本协议的相关义务，相关国家机关或机构可能会对您提起诉讼、罚款或采取其他制裁措施，并要求思亿文化给予协助。造成损害的，您应依法予以赔偿，思亿文化不承担任何责任。<br />
          8.7
          您不得对思亿文化任何部分通过任何方式进行复制、拷贝、出售、转售或用于任何其它商业目的。<br />
          8.8您须对自己在使用思亿文化服务过程中的行为承担法律责任。您承担法律责任的形式包括对受到侵害者进行赔偿，以及在思亿文化首先承担了因您的行为导致的行政处罚或侵权损害赔偿责任后，您应赔偿思亿文化相应的费用。<br />
          <strong>九. 知识产权</strong><br />
          9.1思亿文化平台及平台所使用的任何相关软件、程序、内容，包括但不限于作品、图片以及“思亿文化！”等商业标识、档案、资料、网站构架、网站版面的安排、网页设计、经由本网站或广告商向用户呈现的广告或资讯，均由思亿文化世界所属公司或其它权利人依法享有相应的知识产权，包括但不限于著作权、商标权、专利权或其它专属权利等，受到相关法律的保护。<br />
          9.2未经思亿文化或权利人明示授权，除保证不修改、出租、出借、出售、散布本网站任何资料和资源之外，您亦不可根据网站资源制作成任何种类衍生周边物品。<br />
          9.3思亿文化授予您不可转移及非专属的使用权，使您可以通过单机计算机使用本网站的目标代码（以下简称“软件”），但用户不得且不得允许任何第三方，复制、修改、创作衍生作品、进行还原工程、反向组译，或以其它方式破译或试图破译源代码，或出售、转让“软件”或对“软件”进行再授权，或以其它方式移转“软件”之任何权利。用户同意不以任何方式修改“软件”，或使用修改后的“软件”。<br />
          9.4思亿文化有权在所有由平台提供的功能界面标注“此功能由思亿文化提供”等相关版权信息<br />
          <strong>十. 管辖与法律适用</strong><br />
          10.1 本协议签订地为中华人民共和国北京市。<br />
          10.2本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律及相关法规（不包括冲突法）。<br />
          10.3<strong>若您和</strong><strong>思亿文化</strong
          ><strong
            >之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。</strong
          ><br />
          10.4本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不作为本协议涵义解释的依据。<br />
          10.5如果本协议中任何一条被视为废止、无效或因任何理由不可执行，应视为该条可分且并不影响任何其余条款的有效性和可执行性。<br />
          十一. 附则<br />
          11.1因不可抗力或者其他意外事件，使得本协议的履行不可能、不必要或者无意义的，双方均不承担责任。本协议所称之不可抗力意指不能预见、不能避免并不能克服的客观情况，包括但不限于战争、台风、水灾、火灾、雷击或地震、罢工、暴动、法定疾病、黑客攻击、网络病毒、电信部门技术管制、政府行为或任何其它自然或人为造成的灾难等客观情况。<br />
          11.2您正确提交注册程序所需的资料并确认本协议之日起，本协议在思亿文化与您之间成立并生效。<br />
          11.3本协议生效前的注册用户只需要使用原用户名及密码登录思亿文化平台重新接受并确认本协议，即可继续使用思亿文化的服务并视为同意且接受本协议。<br />
          11.4协议有效期：从您同意本协议或使用思亿文化之日起至您注销思亿文化服务之日止。<br />
          11.5本协议如无特殊规定，双方应当使用电子邮件方式就本协议相关事项进行联系。<br />
          11.6思亿文化各相关部门的电子邮箱以在思亿文化官方网站上注明的为准。<br />
          11.7使用思亿文化即表示接受本协议及其所有附件。<br />
          11.8本协议未明示授权的其他权利仍由思亿文化保留，您在行使这些权利时须另外取得思亿文化的书面许可。思亿文化如果未行使前述任何权利，并不构成对该权利的放弃。<br />
          本协议内容中以加粗、下划线等方式显著标识的条款，请您着重阅读。<br />
          &nbsp;<br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NavHeader from "./../components/NavHeader";
export default {
  name: "ag",
  components: {
    // NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  methods() {
    document.title = "北京思亿文化有限公司";
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.container {
  .ag-con {
    margin: 30px auto;
    .article-content {
      // font-size: 14px;
    }
    // 文字容器
    .t-con {
      margin: 30px auto;
      font-size: 18px;
      line-height: 40px;
      text-align: left;
      p {
        margin: 30px auto;
        // font-size: 12px;
      }
    }
  }
}
</style>
