<template>
  <div class="a2">
    <!-- banner区块 -->
    <div class="banner">
      <img src="/imgs/b3.jpg" alt="" />
    </div>

    <div class="container">
         <div class="yw">
    <!-- 标题 -->
    <div class="y-title">
      <h2>服务项目</h2>
      <div class="t-info">
        <div class="blink"></div>
        <span>产品功能全覆盖，让企业和用户沟通更近一步</span>
        <div class="blink"></div>
      </div>
    </div>

    <!-- 内容 -->
    <div class="y-con">
      <!-- 循环项 -->
      <div
        class="y-item"
        v-for="(item, index) in this.mYW"
        :key="index"
      >
        <!-- 图片 -->
        <img :src="item.img" alt="" />
        <!-- 标题 -->
        <div class="item-title">{{ item.title }}</div>

        <!-- 介绍 -->
        <p>{{ item.p1 }}</p>
        <p>{{ item.p2 }}</p>

        
      </div>
    </div>
  </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 业务
      mYW: [],
    };
  },
  mounted() {
    this.init();
    document.title = "飞拓新创";
  },
  methods: {
    init() {
      this.axios.get("/mock/yw.json").then((res) => {
        const data = res.data;
        this.mYW = data;
      });
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 收费逻辑
    gopay() {
      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
      }
      // 未登录
      if (this.state !== 0) {
        alert("请登录付费3元后可浏览！！！~");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound2/:" + id,
        query: { type: id },
      });
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.banner {
    width: 100%;
    height: 300px;
    img {
        width: 100%;
        height: 100%;
    }
}
.yw {
  margin: 60px auto;
  // 标题
  .y-title {
    width: 100%;
    text-align: center;
    margin: 30px auto;
    margin-top: 60px;
    h2 {
      font-size: 24px;
      font-weight: 400px;
    }
    .t-info {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 16px;
        color: #888;
        margin: 0 10px;
      }
      .blink {
        width: 60px;
        height: 2px;
        background-color: $colorZ;
      }
    }
  }

  // 容器
  .y-con {
    width: 100%;
    // height: 300px;
    // background: pink;
    @include flex();
    // 循环项
    .y-item {
      width: 350px;
      height: 100%;
      background: #fff;
      margin: 0 5px;
      text-align: center;
      padding-bottom: 20px;
      &:hover {
        .item-title {
          color: $colorZ;
        }
      }
      // 图片
      img {
        width: 100px;
        height: 100px;
        margin: 30px auto;
      }

      // 标题
      .item-title {
        color: #333;
        font-size: 18px;
        margin: 15px auto;
        transition: all 0.2s;
        // text-align: c;
      }

      // 介绍
      p {
        width: 100%;
        font-size: 12px;
        color: #888;
        // margin: 15px auto;
        margin-bottom: 10px;
      }

      // 按钮
      .item-btn {
        font-size: 14px;
        // margin-top: 30px;
        padding-bottom: 5px;
        box-sizing: border-box;
        width: 60px;
        cursor: pointer;
        margin: 0 auto;
        margin-top: 30px;
        transition: all 0.3s;
        border: 2px solid #fff;
        border-bottom: 2px solid $colorZ;
        height: 30px;
        line-height: 30px;
        &:hover {
          border: 2px solid $colorZ;
        }
      }
    }
  }
}
</style>